@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  border: none;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  overflow-y: scroll;
}

body {
  margin: 0px;
  background-color: #FFFFFF;
}

body, button {
  color: #212121; /*High Emphasis*/
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

h1, h2, h3, h4, h5, p, span {
  margin: 0px;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: auto;
}
